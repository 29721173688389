$p: ui;
:focus-visible {
  outline: none !important;
}

.pro-Video-chat {
  background: #000000;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  position: relative;
  width: 100%;

  > div {
    height: 100%;
  }

  .dsg-tooltip:before {
    background-color: var(--dsg-gray-900);
    top: -15px;
  }

  .dsg-tooltip--n:after,
  .dsg-tooltip--ne:after {
    border-top-color: var(--dsg-gray-900);
    top: -15px;
  }

  .dsg-tooltip--nw:after {
    border-top-color: var(--dsg-gray-900);
  }

  .dsg-tooltip--w:after {
    border-left-color: var(--dsg-gray-900);
  }
}

.whereby-container {
  height: 90%;

  whereby-embed {
    height: 100%;
    width: 100%;
  }
}

.pro-Video-chat__message {
  height: 0;

  span {
    margin: 0 10px;
    background: rgb(34 34 34 / 85%);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    top: 140px;
    position: relative;
  }

  &:not(.dsg-color--error) {
    color: #ffffff;
  }
}
/*
 * Logo Overflowing
 */
.pro-Video-chat__topbar {
  display: block;
  z-index: 1;
  padding: 5px 20px;
  top: 0;
  right: 0;
  left: 0;
  background: #000;
  color: #ffffff;
  font-size: 12px;
}

.pro-Video-chat__logo {
  max-width: 70px;
}

.pro-Video-chat__footer {
  bottom: 0;
  position: absolute;
  width: 100%;
}


.pro-Video-chat__security-btn {
  color: #ffffff;
  position: absolute;
  bottom: 13px;
  left: 20px;
  opacity: 0;
  z-index: 2;
  transition: 0.3s linear opacity;

  &.active {
    opacity: 0.7;
    cursor: pointer;
  }

  &.active:hover {
    opacity: 1;
  }
}

.pro-Video-chat__footer-message {
  color: #ffffff;
  background: rgba(0, 0, 0, 0.6);
  text-align: center;
  font-size: 10px;
  padding: 5px 10px;
  z-index: 99999;
  bottom: 23px !important;
  transition: 0.3s linear opacity;
  width: 100%;
  position: fixed;
}

.pro-title {
  font-weight: bold;
  font-family: inherit;
  font-size: 20px;
  border-bottom: 0;
  line-height: normal;
}
