$p: ui;
@tailwind base;
@tailwind components;

@import '@doctena-org/ui-components/dist/esm/autocomplete/styles.scss';
@import '@doctena-org/ui-components/dist/esm/button/styles.scss';
@import '@doctena-org/ui-components/dist/esm/card/styles.scss';
@import '@doctena-org/ui-components/dist/esm/checkbox/styles.scss';
@import '@doctena-org/ui-components/dist/esm/context-menu/styles.scss';
@import '@doctena-org/ui-components/dist/esm/drop-down/styles.scss';
@import '@doctena-org/ui-components/dist/esm/form-input/styles.scss';
@import '@doctena-org/ui-components/dist/esm/form-select/styles.scss';
@import '@doctena-org/ui-components/dist/esm/header/styles.scss';
@import '@doctena-org/ui-components/dist/esm/heading/styles.scss';
@import '@doctena-org/ui-components/dist/esm/icon/styles.scss';
@import '@doctena-org/ui-components/dist/esm/info-message/styles.scss';
@import '@doctena-org/ui-components/dist/esm/input/styles.scss';
@import '@doctena-org/ui-components/dist/esm/notification/styles.scss';
@import '@doctena-org/ui-components/dist/esm/overlay/styles.scss';
@import '@doctena-org/ui-components/dist/esm/paragraph/styles.scss';
@import '@doctena-org/ui-components/dist/esm/popup/styles.scss';
@import '@doctena-org/ui-components/dist/esm/radio/styles.scss';
@import '@doctena-org/ui-components/dist/esm/select/styles.scss';
@import '@doctena-org/ui-components/dist/esm/table/styles.scss';
@import '@doctena-org/ui-components/dist/esm/toggle/styles.scss';
@import '@doctena-org/ui-configs/src/styles/global.css';

@tailwind utilities;

:root {
  --header-height: 96px;
  --content-width: 2464px;
  --footer-height: 64px;
}

#__next {
  @apply
  bg-gray-095
  flex
  flex-col
  items-center
  min-h-screen
  text-gray-025
  ;
}

hr {
  @apply border-gray-085 text-gray-085;
}
.ui-button:focus-within {
  outline: none !important;
}

label {
  &.ui-checkbox{
    background-color: rgb(250 250 250);
    padding: 1.5rem;
    border-radius: 1rem;
    &:hover {
      background-color: rgb(242 242 242);
    }
  }
  &:focus-within {
    outline: none !important;
  }
}
// Overwrite component
#edit-form .ui-form-select-base {
    height: 136px !important;
  }
